import Button from "react-bootstrap/Button";
import { AiOutlineCheck } from "react-icons/ai";
import { IFileActionProps } from "../../../Interfaces/IFileActionProps";

function ApproveFile({
  file,
  isDisabled,
  onActionButtonClick,
}: IFileActionProps): JSX.Element {
  const handleClick = () => {
    onActionButtonClick(file);
  };
  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleClick}
        style={{ marginRight: "5px" }}
        className={isDisabled ? "action-button" : "action-button green"}
        data-testid="approve-btn"
      >
        <AiOutlineCheck />
      </Button>
    </>
  );
}

export default ApproveFile;
