import { useState } from "react";
import GenerateApiKeyForm from "./Components/GenerateApiKeyForm";
import { Button, Container } from "react-bootstrap";
import ApiKeyDisplayModal from "./Components/ApiKeyDisplayModal";
import { IApiKeyDetails } from "../../Interfaces/IApiKeyDetails";
import { IoIosArrowBack } from "react-icons/io";
import ApiKeyTable from "./Components/ApiKeyTable";

function ApiKeyPage() {
    const [showGeneratedKey, setShowGeneratedKey] = useState(false);
    const [generatedApiKey, setGeneratedApiKey] = useState<IApiKeyDetails | null>(
        null
    );
    const [resetFormTrigger, setResetFormTrigger] = useState(false);

    const handleCloseApiKeyDisplayModal = () => {
        setShowGeneratedKey(false);
        setGeneratedApiKey(null);
        setResetFormTrigger((prev) => !prev);
    };

    return (
        <Container className="PageContainer ">
            <Button href="/" className="back-button" data-testid="IoIosArrowBack">
                {" "}
                <IoIosArrowBack />
                Back
            </Button>

            <GenerateApiKeyForm
                setShowApiKey={setShowGeneratedKey}
                setGeneratedApiKey={setGeneratedApiKey}
                resetFormTrigger={resetFormTrigger}
            />

            <ApiKeyTable resetTableTrigger={resetFormTrigger} />

            {showGeneratedKey && generatedApiKey != null && (
                <ApiKeyDisplayModal
                    keyName={generatedApiKey.keyName}
                    email={generatedApiKey.email}
                    apiKey={generatedApiKey.apiKey}
                    appId={generatedApiKey.appId}
                    show={showGeneratedKey}
                    onClose={handleCloseApiKeyDisplayModal}
                />
            )}
        </Container>
    );
}

export default ApiKeyPage;
