import { Button, Card } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import { useTemplate } from "../Hooks/UseTemplate";

function DownloadCard() {
  const { isDownloading, downloadTemplate } = useTemplate();

  return (
    <Card style={{ width: "15rem" }} className="text-center Card">
      {isDownloading ? (
        <LoadingSpinner />
      ) : (
        <Card.Img
          variant="top"
          src="./images/file_download.png"
          className="card-image mx-auto"
        />
      )}

      <Card.Body>
        <Card.Title>
          <h5 className="font-size-xs">Download Template</h5>
        </Card.Title>

        <Button
          className="Button"
          disabled={isDownloading}
          onClick={downloadTemplate}
        >
          <span
            style={{
              fontSize: "0.9rem",
            }}
          >
            Download File
          </span>
        </Button>
      </Card.Body>
    </Card>
  );
}

export default DownloadCard;
