import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlineDelete } from "react-icons/ai";
import { deleteReferral } from "../Services/IntroducerService";
import { toast } from "react-toastify";
import { IDeleteFileProps } from "../Interfaces/IDeleteFileProps";

function DeleteFile({
  fileId,
  getFiles,
  fileStatus,
}: IDeleteFileProps): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = async () => {
    setIsLoading(true);
    await deleteReferral(fileId)
      .then(() => {
        handleClose();
        getFiles();
        toast.success("File deleted successfully");
      })
      .catch((e) => {
        handleClose();
        toast.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {fileStatus !== "Accepted" && (
        <Button
          className="delete-icon"
          data-testid="delete-btn"
          variant="outline-light"
          onClick={handleShow}
        >
          <AiOutlineDelete color="#000000" />
        </Button>
      )}
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Confirmation</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Are you sure you want to delete this file?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            data-testid="close-btn"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
          <Button
            disabled={isLoading}
            variant="danger"
            data-testid="delete-modalbtn"
            onClick={handleDelete}
          >
            <span>Delete</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteFile;
