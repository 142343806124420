import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getFileData } from "../Services/IntroducerService";
import { toast } from "react-toastify";
import LoadingSpinner from "./LoadingSpinner";
import { updateFileContent } from "../Services/ReviewerService";
import ConfirmationModal from "./ConfirmationModal";
import { checkEmptyCells, replaceCsvTableData } from "../Utils/helper";
import { IFileModalProps } from "../Interfaces/IFileModalProps";

const FileModal: React.FC<IFileModalProps> = ({
  show,
  handleClose,
  file,
  isModalEditable,
}) => {
  const [headers, setHeaders] = useState<string[]>([]);
  const [data, setData] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [modifiedData, setModifiedData] = useState<string[]>([]);
  const [originalData, setOriginalData] = useState<string>("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleInputChange = (
    rowIndex: number,
    cellIndex: number,
    value: string
  ) => {
    setModifiedData(
      replaceCsvTableData(rowIndex, cellIndex, value, modifiedData)
    );
  };

  const getFileContent = useCallback(async () => {
    setIsUploading(true);
    await getFileData(file?.fileId ?? "")
      .then((res: any) => {
        const response: string = res.data;
        const rows = response.split("\n");
        const headings = rows[0].split(",");
        const filteredHeadings = headings.filter(
          (heading: string) => heading.trim() !== ""
        );
        setOriginalData(response);
        setData(rows);
        setModifiedData(rows);
        setHeaders(filteredHeadings);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, [file]);

  useEffect(() => {
    if (show && file != null) getFileContent();
  }, [show, file, getFileContent]);

  const handleSaveChanges = async () => {
    if (file != null && isModalEditable) {
      setIsUploading(true);
      await updateFileContent(file, modifiedData)
        .then(() => {
          setData(modifiedData);
          setOriginalData(modifiedData.join("\n"));
          toast.success("File Updated Successfully");
        })
        .catch(async (e) => {
          setData(originalData.split("\n"));
          toast.error(e.message);
        })
        .finally(() => {
          setEditMode(false);
          setIsUploading(false);
          setShowConfirmationModal(false);
        });
    } else toast.error("Error updating file. Please try again.");
  };

  const onSaveButtonClick = async () => {
    const csvValidationResult = checkEmptyCells(modifiedData);
    const emptyFields = csvValidationResult.filter((item) => item.isEmpty);
    if (emptyFields.length > 0) {
      const emptyFieldNames = emptyFields.map((item) => item.column).join(", ");
      toast.error(`The following fields are empty: ${emptyFieldNames}`);
    } else {
      setShowConfirmationModal(true);
    }
  };

  const handleModalClose = () => {
    handleClose();
    setEditMode(false);
    resetModalContent();
  };

  const resetModalContent = () => {
    setData([]);
    setOriginalData("");
    setModifiedData([]);
    setHeaders([]);
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>{file?.displayName}</h5>
        </Modal.Title>
      </Modal.Header>
      {isUploading ? (
        <LoadingSpinner />
      ) : (
        <Modal.Body>
          <div className="table-responsive">
            <table className="table w-auto editable-table">
              <thead className="thead-light">
                <tr>
                  {headers.map((heading, index) => (
                    <th key={index}>
                      <p>{heading}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.length > 0
                  ? data.slice(1).map((row, rowIndex) => {
                    const rowData = row.split(",");
                    const hasData = rowData.some(
                      (cell) => cell.trim() !== ""
                    );
                    return hasData ? (
                      <tr key={rowIndex}>
                        {rowData.map((cell, cellIndex) => (
                          <td key={cellIndex}>
                            <span className="header-cell">
                              {editMode ? (
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-label={cell}
                                  defaultValue={cell}
                                  onChange={(e) =>
                                    handleInputChange(
                                      rowIndex,
                                      cellIndex,
                                      e.target.value
                                    )
                                  }
                                />
                              ) : (
                                cell
                              )}
                            </span>
                          </td>
                        ))}
                      </tr>
                    ) : null;
                  })
                  : (
                    <tr>
                      <td colSpan={headers.length}>No data found.</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      )}
      {isModalEditable && (
        <Modal.Footer>
          <Button
            className={editMode ? "ButtonSecondary" : "Button"}
            variant="secondary"
            disabled={!modifiedData.length}
            onClick={() => {
              setEditMode(!editMode);
              setData(originalData.split("\n"));
            }}
          >
            <span>{editMode ? "Cancel" : "Edit"}</span>
          </Button>
          <Button
            disabled={!editMode || isUploading}
            className="Button"
            onClick={onSaveButtonClick}
          >
            <span>Save</span>
          </Button>
        </Modal.Footer>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleConfirmation={handleSaveChanges}
          desc="Are you sure you want save these changes?"
        />
      )}
    </Modal>
  );
};

export default FileModal;
