import { useEffect, useState } from "react";
import { ITableData } from "../Interfaces/ITableData";
import { Button, Row } from "react-bootstrap";
import FileModal from "./FileModal";
import DeleteFile from "./DeleteFile";
import { AiOutlineFile } from "react-icons/ai";
import { getAllFiles } from "../Services/IntroducerService";
import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
import LoadingSpinner from "./LoadingSpinner";
import { useAppContext } from "../AppContext";
import ResetSortButton from "./ResetSortButton";
import { TABLE_COLUMNS } from "../Utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { formattedDate, getTake } from "../Utils/helper";

function UserTable() {
  const take = getTake();
  const { user } = useAppContext();
  const [files, setFiles] = useState<ITableData[]>([]);
  const [selectedFile, setSelectedFile] = useState<ITableData | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [limit, setLimit] = useState<number>(take);
  const [offset, setOffset] = useState<number>(0);
  const [isDescending, setIsDescending] = useState<boolean>(true);
  const [hasFiles, setHasFiles] = useState(true);
  const [sortBy, setSortBy] = useState<string>(TABLE_COLUMNS.dateUploaded);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc" | null>(
    "desc"
  );

  // Fetch files
  async function fetchFiles(
    limit: number,
    offset: number,
    sortBy: string,
    isDescending: boolean
  ): Promise<any[]> {
    setLimit(take);
    const fetchedFiles = await getAllFiles(
      user.oid,
      limit,
      offset,
      sortBy,
      isDescending
    );
    return fetchedFiles;
  }

  // Handle file click
  const handleFileClick = async (file: ITableData) => {
    setSelectedFile(file);
    setShowModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Handle sorting
  async function handleSort(key: any) {
    try {
      const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(newSortDirection);
      setSortBy(key);
      setIsDescending(newSortDirection === "desc");
      setLimit(take);

      const fetchedFiles = await fetchFiles(
        take,
        0,
        key,
        newSortDirection === "desc"
      );
      setFiles(fetchedFiles);
    } catch (error) {
      toast.error("Error in sorting");
    }
  }

  // Reset sorting
  async function handleResetAll() {
    try {
      setLimit(take);
      setSortBy(TABLE_COLUMNS.dateUploaded);
      setIsDescending(true);

      const _files = await fetchFiles(
        take,
        0,
        TABLE_COLUMNS.dateUploaded,
        true
      );
      setFiles(_files);
      setHasFiles(_files.length >= take);
    } finally {
      setSortDirection("desc");
      setOffset(0);
    }
  }

  // Load more files
  async function handleLoad() {
    const newOffset: number = offset + limit;
    setLimit(take);
    try {
      const _files = await fetchFiles(limit, newOffset, sortBy, isDescending);
      if (_files.length >= 1) {
        setFiles([...files, ..._files]);
        setOffset(newOffset);
      } else {
        setHasFiles(false);
      }
    } catch (error) {
      toast.error("Files couldn't be loaded. Please try again.");
    }
  }

  async function getFiles() {
    try {
      const _files = await fetchFiles(
        limit,
        offset,
        TABLE_COLUMNS.dateUploaded,
        isDescending
      );
      setFiles(_files);
      setHasFiles(_files.length >= take);
    } catch (error) {
      toast.error("Failed to fetch files. Please try again.");
    }
  }

  // Initial fetch on component mount
  useEffect(() => {
    getFiles();
  }, []);

  return (
    <>
      <div className="table-responsive">
        {files.length === 0 ? (
          <Card
            border="light"
            style={{ marginTop: "10%", alignItems: "center" }}
          >
            <Card.Body>No files uploaded yet.</Card.Body>
          </Card>
        ) : (
          <>
            <Row>
              <InfiniteScroll
                scrollThreshold={0.9}
                dataLength={files.length}
                next={handleLoad}
                hasMore={hasFiles}
                loader={<LoadingSpinner />}
                endMessage={
                  <p className="end-message">You've reached the end.</p>
                }
              >
                <table
                  className="table justify-content-center mx-auto caption-top"
                  data-testid="userTable"
                >
                  <caption>
                    <ResetSortButton handleResetAll={handleResetAll} />
                  </caption>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        onClick={() => handleSort(TABLE_COLUMNS.displayName)}
                      >
                        <span className="table-header">
                          File Name
                          {sortBy === TABLE_COLUMNS.displayName &&
                            (sortDirection === "asc" ? " ↑" : " ↓")}
                        </span>
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleSort(TABLE_COLUMNS.dateUploaded)}
                      >
                        <span className="header-cell table-header">
                          Uploaded Date
                          {sortBy === TABLE_COLUMNS.dateUploaded &&
                            (sortDirection === "asc" ? " ↑" : " ↓")}
                        </span>
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleSort(TABLE_COLUMNS.status)}
                      >
                        <span className="header-cell table-header">
                          Status
                          {sortBy === TABLE_COLUMNS.status &&
                            (sortDirection === "asc" ? " ↑" : " ↓")}
                        </span>
                      </th>
                      <th scope="col">
                        <span className="header-cell table-header">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {files?.length > 0 &&
                      files.map((file) => (
                        <tr key={file.fileId}>
                          <td style={{ whiteSpace: "pre-wrap" }}>
                            <Button
                              className="Button-link"
                              onClick={() => handleFileClick(file)}
                            >
                              <span>
                                <AiOutlineFile />
                                {file.displayName}
                              </span>
                            </Button>
                          </td>
                          <td>
                            <span className="header-cell">
                              {formattedDate(file.dateUploaded)}
                            </span>
                          </td>
                          <td>
                            <span className="header-cell">{file.status}</span>
                          </td>
                          <td>
                            <span
                              data-testid="dataFlow_icon_delete"
                              className="header-cell"
                            >
                              <DeleteFile
                                getFiles={getFiles}
                                fileId={file.fileId}
                                fileStatus={file.status}
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </Row>
          </>
        )}
        <FileModal
          getFiles={getFiles}
          show={showModal}
          handleClose={handleCloseModal}
          file={selectedFile}
          isModalEditable={false}
        />
      </div>
    </>
  );
}
export default UserTable;
