import { ReactNode } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface ITooltipProps {
  id: string;
  children?: ReactNode;
  title: string;
  disable?: boolean;
  action: () => void;
  variant?: string;
}
export const TooltipBtn = ({
  id,
  children,
  title,
  disable,
  action,
  variant,
}: ITooltipProps) => (
  <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
    <Button
      onClick={action}
      disabled={disable}
      data-testid={id}
      variant={variant ? variant : "light"}
      className={variant ? "" : "tooltip-btn"}
    >
      {children}
    </Button>
  </OverlayTrigger>
);
