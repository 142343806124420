export interface IUserDetails {
  b2CRef: string;
  email: string;
  role: string;
  status: UserStatus;
  deleted: boolean;
}

export interface IPatchUserDetails {
  status: UserStatus;
}

export interface IPostUserDetails {
  email: string;
  role: string;
}

export enum UserStatus {
  Invited = "Invited",
  Active = "Active",
  Revoked = "Revoked",
  ApiServiceUser = "ApiServiceUser",
}