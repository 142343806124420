import axiosInstance from "../Config/axiosConfig";
import { ITableData } from "../Interfaces/ITableData";

export async function getAllFiles(
  take: number,
  skip: number,
  orderBy: string,
  isDescending: boolean
): Promise<ITableData[]> {
  const url = `/api/referrals?take=${take}&skip=${skip}&orderBy=${orderBy}&isDescending=${isDescending}`;
  return await axiosInstance.get(url).then((res) => {
    if (!res?.data) return [];
    const newFileArray: ITableData[] = res.data.map((fileItem: any) => ({
      fileId: fileItem.referralId,
      userEmail: fileItem.userEmail,
      fileName: fileItem.fileName,
      dateUploaded: fileItem.dateCreated,
      status: fileItem.status,
      displayName: fileItem.displayname,
    }));
    return newFileArray;
  });
}

export async function approveReferral(referralId: string) {
  const response = await axiosInstance.patch(
    `/api/referrals/${referralId}/approve`
  );
  return response.data;
}

export async function rejectReferral(referralId: string, note?: string) {
  const response = await axiosInstance.patch(
    `/api/referrals/${referralId}/reject`,
    { note }
  );
  return response.data;
}

export async function updateFileContent(
  file: ITableData,
  updatedData: string[]
) {
  const csvContent = updatedData.join("\n");
  const blob = new Blob([csvContent], { type: "text/csv" });
  const formData = new FormData();
  formData.append("file", blob, file.fileName);

  return await axiosInstance.put(`/api/referrals/${file.fileId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
