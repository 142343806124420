import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { FORBIDDEN_ERROR_MSG, INTERNAL_ERROR_MSG } from "../Utils/constants";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function handleErrorMessages(error: AxiosError) {
  switch (error.request.status) {
    case 500:
      throw new Error(INTERNAL_ERROR_MSG);
    case 403:
      throw new Error(FORBIDDEN_ERROR_MSG);
    default:
      throw new Error(error.request.response);
  }
}

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("access_token");
    if (authToken) {
      config.headers.authorization = `bearer ${authToken}`;
    }
    return config;
  },
  (error: AxiosError) => {
    handleErrorMessages(error);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    handleErrorMessages(error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
