import { AuthenticatedTemplate } from "@azure/msal-react";
import NavigationBar from "../Components/NavigationBar";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <div className="vstack PageContainer">
      <div>
        <AuthenticatedTemplate>
          <NavigationBar />
        </AuthenticatedTemplate>
      </div>
      <Outlet />
    </div>
  );
}
