import React from "react";
import ReactDOM from "react-dom/client";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { msalConfig } from "./Utils/msalConfig";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  // handle auth redirect/do all initial setup for msal
  msalInstance
    .handleRedirectPromise()
    .then((authResult) => {
      // Check if user signed in
      const account = msalInstance.getActiveAccount();
      if (!account) {
        msalInstance.loginRedirect();
      }
    })
    .catch((err) => {
      console.log(err);
    });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App pca={msalInstance} />
      </BrowserRouter>
    </React.StrictMode>
  );
});
reportWebVitals();
