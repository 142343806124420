import React, { useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container, Form, Button } from "react-bootstrap";
import { createUser } from "../Services/AdminService";
import LogoutModal from "./LogoutModal";
import InviteUserModal from "../Pages/Admin/Components/InviteUserModal";
import { AppContext } from "../AppContext";
import { USER_ROLES } from "../Utils/constants";
import UploadTemplateButton from "./UploadJsonButton";
import { useTemplate } from "../Hooks/UseTemplate";
import { useLocation } from "react-router-dom";

function NavigationBar() {
  const { instance } = useMsal();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { downloadTemplate, isDownloading } = useTemplate();
  let location = useLocation();

  const [pathname = location.pathname] = useState();

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const inviteUser = () => {
    setShowInviteUserModal(true);
  };

  const handleConfirmLogout = async () => {
    try {
      sessionStorage.clear();
      await instance.logoutRedirect();
    } catch (e) {
      toast.error("Logout failure! Please try again...");
    }
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false);
  };

  const handleCancelUserInvite = () => {
    setShowInviteUserModal(false);
  };

  const handleUserInvite = async ({
    email,
    role,
  }: {
    email: string;
    role: string;
  }) => {
    if (!isLoading) {
      setIsLoading(true);
      await createUser({ email, role })
        .then(() => {
          toast.success(`${role} invitation sent to ${email}`);
          window.location.reload();
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Navbar expand="md" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              className="Logo"
              src="/images/cl-logo-transparent.png"
              width="200px"
              height="50px"
              alt="ChadwickLawrence Logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="justify-content-end flex-grow-1 pe-3"
              activeKey={pathname === "/ApiKeys" ? "/ApiKeys" : ""}
            >
              {(user.role === USER_ROLES.SUPER_ADMIN ||
                user.role === USER_ROLES.ADMIN) && (
                  <Nav.Link onClick={inviteUser} className="navbar-items">
                    Invite User
                  </Nav.Link>
                )}

              {user.role === USER_ROLES.SUPER_ADMIN && (
                <>
                  <Nav.Link
                    onClick={downloadTemplate}
                    className="navbar-items"
                    data-testid="NavLinkDownload"
                    disabled={isDownloading}
                  >
                    Download Template
                  </Nav.Link>
                  <UploadTemplateButton />
                  <Nav.Link href="/ApiKeys" className="navbar-items">
                    API Keys
                  </Nav.Link>
                </>
              )}
            </Nav>

            <Form className="d-flex ">
              <Navbar>
                <div className="ml-auto">
                  <Button
                    data-testid="logoutButton"
                    className="Button ml-2"
                    onClick={handleLogout}
                    style={{ marginLeft: "10px", marginTop: "5%" }}
                  >
                    <span>Logout</span>
                  </Button>
                </div>
              </Navbar>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LogoutModal
        data-testid="logoutConfirmationModal"
        show={showLogoutModal}
        onCancel={handleCancelLogout}
        onConfirm={handleConfirmLogout}
      />
      <InviteUserModal
        data-testid="inviteUserModal"
        show={showInviteUserModal}
        onCancel={handleCancelUserInvite}
        onSubmit={handleUserInvite}
      />
    </>
  );
}

export default NavigationBar;
