import axiosInstance from "../Config/axiosConfig";
import { IUploadCSVResponse } from "../Interfaces/IUploadCSVResponse";
import { ITableData } from "../Interfaces/ITableData";
import { downloadFile } from "../Utils/helper";

export async function uploadCSVFile(
  file: File,
  userId: string
): Promise<IUploadCSVResponse> {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("userId", userId);
  return await axiosInstance.post("/api/referrals", formData);
}

export async function getDefaultCSVTemplate(): Promise<boolean> {
  return await axiosInstance.get("/api/templates").then((res) => {
    if (res?.data) {
      downloadFile(res.data, "Referral_Template.csv");
      return true;
    }
    return false;
  });
}

export async function getAllFiles(
  userId: string,
  take: number,
  skip: number,
  orderBy: string,
  isDescending: boolean
): Promise<ITableData[]> {
  return await axiosInstance
    .get(
      `/api/referrals?take=${take}&skip=${skip}&orderBy=${orderBy}&isDescending=${isDescending}`
    )
    .then((res) => {
      if (!res?.data) return [];
      const newFileArray: ITableData[] = res.data.map((fileItem: any) => ({
        fileId: fileItem.referralId,
        fileName: fileItem.fileName,
        dateUploaded: fileItem.dateCreated,
        status: fileItem.status,
        displayName: fileItem.displayname,
      }));
      return newFileArray;
    });
}

export async function getFileData(referralId: string): Promise<string> {
  return await axiosInstance.get(`/api/referrals/${referralId}/file`);
}

export async function deleteReferral(referralId: string): Promise<void> {
  return await axiosInstance.delete(`/api/referrals/${referralId}`);
}
