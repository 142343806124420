import { Form, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { IUserDetails } from "../../../Interfaces/IUserDetails";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import ResetSortButton from "../../../Components/ResetSortButton";
import { getTake } from "../../../Utils/helper";
import { TABLE_COLUMNS } from "../../../Utils/constants";
import { getAllUsers } from "../../../Services/AdminService";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";

interface AdminTableProps {
  userList: IUserDetails[];
  setUserList: React.Dispatch<React.SetStateAction<IUserDetails[]>>;
  setSelectedUser: React.Dispatch<React.SetStateAction<IUserDetails | null>>;
  selectedUser: IUserDetails | null;
}

function AdminTable({
  userList,
  setUserList,
  setSelectedUser,
  selectedUser,
}: AdminTableProps) {
  const take = getTake();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string>(TABLE_COLUMNS.dateUploaded);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc" | null>(
    "asc"
  );
  const [hasUsers, setHasUsers] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(take);
  const [offset, setOffset] = useState<number>(0);
  const [isDescending, setIsDescending] = useState<boolean>(true);
  // Reusable Code
  async function getUserList(
    limit: number,
    offset: number,
    sortBy: string,
    isDescending: boolean
  ) {
    try {
      const _users = await getAllUsers(limit, offset, sortBy, isDescending);
      return _users;
    } catch (error) {
      toast.error("Error occurred while fetching users.");
    }
  }

  async function getUsers() {
    try {
      const _users = (await getUserList(
        limit,
        offset,
        sortBy,
        isDescending
      )) as IUserDetails[];
      if (_users.length > 0) {
        setUserList(_users);
        setIsLoading(false);
      }
      if (_users.length < take) {
        setHasUsers(false);
      }
    } catch (error) {
      toast.error("Error occurred while fetching users.");
    }
  }

  // Gets users on initial load.
  useEffect(() => {
    getUsers();
  }, []);

  async function handleSort(key: string) {
    try {
      const newSortDirection = sortDirection === "desc" ? "asc" : "desc";
      setSortDirection(newSortDirection);
      setSortBy(key);
      setIsDescending(newSortDirection === "desc");
      setLimit(take);
      setHasUsers(true);

      const _users = (await getUserList(
        limit,
        0,
        key,
        newSortDirection === "desc"
      )) as IUserDetails[];

      if (_users.length > 0) {
        setUserList(_users);
      }
    } catch (error) {
      toast.error("Error in sorting");
    } finally {
      setOffset(0);
    }
  }

  const handleResetAll = async () => {
    try {
      setLimit(take);

      const _users = (await getUserList(
        take,
        0,
        TABLE_COLUMNS.dateUploaded,
        false
      )) as IUserDetails[];

      if (_users.length > 0) {
        setUserList(_users);
      }
      if (_users.length < take) {
        setHasUsers(false);
      } else {
        setHasUsers(true);
      }
    } finally {
      setSortBy(TABLE_COLUMNS.dateUploaded);
      setIsDescending(false);
      setSortDirection("asc");
      setOffset(0);
    }
  };

  const handleSelectUser = (user: IUserDetails) => {
    setSelectedUser(user.b2CRef === selectedUser?.b2CRef ? null : user);
  };

  const handleLoad = async () => {
    const newOffset: number = offset + take;
    try {
      const _users = (await getUserList(
        limit,
        newOffset,
        sortBy,
        isDescending
      )) as IUserDetails[];
      if (_users.length > 0) {
        setUserList([...userList, ..._users]);
        setOffset(newOffset);
      } else {
        setHasUsers(false);
      }
    } catch (error) {
      toast.error("Error occurred while loading users.");
    }
  };

  return (
    <div className="table-responsive">
      {!isLoading && userList.length === 0 ? (
        <Card className="mt-5 text-center" border="light">
          <Card.Body>No users found</Card.Body>
        </Card>
      ) : (
        <InfiniteScroll
          scrollThreshold={0.9}
          dataLength={userList.length}
          next={handleLoad}
          hasMore={hasUsers}
          loader={<LoadingSpinner />}
          endMessage={<p className="end-message">You've reached the end.</p>}
        >
          <table
            className="table justify-content-center mx-auto caption-top"
            data-testid="adminTable"
          >
            <caption>
              <ResetSortButton
                handleResetAll={handleResetAll}
                data-testid="resetSort"
              />
            </caption>
            <thead>
              <tr>
                <th></th>
                <th
                  onClick={() => handleSort(TABLE_COLUMNS.adminEmail)}
                  data-testid="emailId"
                >
                  <span className="table-header">
                    Email Id
                    {sortBy === TABLE_COLUMNS.adminEmail &&
                      (sortDirection === "asc" ? " ↑" : " ↓")}
                  </span>
                </th>
                <th onClick={() => handleSort(TABLE_COLUMNS.role)}>
                  <span className="table-header">
                    Role
                    {sortBy === TABLE_COLUMNS.role &&
                      (sortDirection === "asc" ? " ↑" : " ↓")}
                  </span>
                </th>
                <th onClick={() => handleSort(TABLE_COLUMNS.status)}>
                  <span className="table-header">
                    Status
                    {sortBy === TABLE_COLUMNS.status &&
                      (sortDirection === "asc" ? " ↑" : " ↓")}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {userList.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={row.b2CRef === selectedUser?.b2CRef}
                      onChange={() => handleSelectUser(row)}
                      data-testid="formCheck"
                    />
                  </td>
                  <td>{row.email}</td>
                  <td>
                    <span>{row.role}</span>
                  </td>
                  <td>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default AdminTable;
