import Button from "react-bootstrap/Button";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { rejectReferral } from "../../../Services/ReviewerService";
import { toast } from "react-toastify";

interface IRejectProps {
  fileId: string;
  isDisabled: boolean;
  onSuccess?: () => void;
}

function RejectFile({
  fileId,
  isDisabled,
  onSuccess,
}: IRejectProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [note, setNote] = useState<string>();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await rejectReferral(fileId, note);
      toast.success("Referral rejected!")
      setShow(false);
      if (onSuccess) onSuccess();
    } catch {
      toast.error("Failed to perform the action. Please try again.");
    }
    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleShow}
        style={{ marginLeft: "5px" }}
        className={isDisabled ? "action-button" : "action-button red"}
        data-testid="reject-btn"
      >
        <AiOutlineClose />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Add rejection note</Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="rejectionNote" className="m-3 mt-0">
            <Form.Control
              autoFocus
              required
              placeholder="Reason for rejection"
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
          <Modal.Footer>
            <Button
              variant="secondary"
              data-testid="cancel-rejectbtn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="Button"
              data-testid="reject-modalbtn"
              disabled={isLoading}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default RejectFile;
