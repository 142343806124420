import axiosInstance from "../Config/axiosConfig";
import {
  IPatchUserDetails,
  IPostUserDetails,
  IUserDetails,
} from "../Interfaces/IUserDetails";
import { IUserLogs } from "../Interfaces/IUserLogs";
import { IUserLogsResponse } from "../Interfaces/IUserLogsResponse";
import { convertToCSV, downloadFile } from "../Utils/helper";

export async function getAllUsers(
  take: number,
  skip: number,
  orderBy: string,
  isDescending: boolean
): Promise<IUserDetails[]> {
  const url = `/api/users?take=${take}&skip=${skip}&orderBy=${orderBy}&isDescending=${isDescending}`;
  try {
    const response = await axiosInstance.get(url);
    if (!response?.data) return [];
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserById(
  userId: string
): Promise<IUserDetails | null> {
  try {
    const response = await axiosInstance.get(`api/users/${userId}`);
    if (!response?.data) return null;
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function patchUser(
  userId: string,
  updatedUserData: IPatchUserDetails
): Promise<IUserDetails> {
  const response = await axiosInstance.patch(
    `api/users/${userId}`,
    updatedUserData
  );
  return response.data;
}

export async function createUser(
  userData: IPostUserDetails
): Promise<IUserDetails | null> {
  try {
    const response = await axiosInstance.post("api/users", userData);
    if (!response?.data) return null;
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUserLogs(userId: string | null): Promise<boolean> {
  try {
    const response = await axiosInstance.get(`api/users/${userId}/signinlogs`);
    if (response?.data.length > 0) {
      const newLogsArray: IUserLogs[] = response.data.map(
        (logItem: IUserLogsResponse) => ({
          emailId: logItem.email,
          signInTime: logItem.signInTime,
          status: logItem.status.errorCode === 0 ? "Success" : "Failure",
        })
      );
      const email: string = response.data[0].email;
      downloadFile(convertToCSV(newLogsArray), `UserLogs_${email}.csv`);
      return true;
    }
    return false;
  } catch (error) {
    throw error;
  }
}

export async function deleteUserById(
  userId: string | null
): Promise<IUserDetails> {
  const res = await axiosInstance.delete(`api/users/${userId}`);
  return res.data;
}
