import { Container, Row } from "react-bootstrap";
import ReviewerTable from "./Components/ReviewerTable";
import ScrollToTop from "react-scroll-to-top";

function ReviewerPage() {
  return (
    <div className="vstack PageContainer">
      <Container className="w-100">
        <Row
          style={{
            height: "70%",
            justifyContent: "center",
          }}
        >
          <ReviewerTable />
        </Row>
        <ScrollToTop smooth className="scroll-top" color="white" />
      </Container>
    </div>
  );
}

export default ReviewerPage;
