import { Outlet } from "react-router-dom";
import NoAccess from "../Pages/NoAccess";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import LoadingSpinner from "../Components/LoadingSpinner";

function RouteGuard({ allowedRole }: { allowedRole: string }) {
  const { user } = useContext(AppContext);

  if (!user.role) return <LoadingSpinner />;

  return user.role === allowedRole ? <Outlet /> : <NoAccess />;
}

export default RouteGuard;
