import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { GrRefresh } from "react-icons/gr";
import { TooltipBtn } from "../../../Components/TooltipBtn";
import { IApiKeyDetails } from "../../../Interfaces/IApiKeyDetails";
import { deleteKeyByID, getAllKeys, regenerateKeyByID } from "../../../Services/SuperAdminService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import { formattedDate } from "../../../Utils/helper";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import CopyableTextField from "./CopyableTextField";

interface ApiKeyTableProps {
  resetTableTrigger: boolean;
}

function ApiKeyTable(props: ApiKeyTableProps) {
  const { resetTableTrigger } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedAppId, setSelectedAppId] = useState<string>("");
  const [keys, setKeys] = useState<IApiKeyDetails[]>([]);

  const handleDelete = async () => {
    await deleteKeyByID(selectedAppId)
      .then(() => {
        toast.success("Deleted successfully");
        setKeys((prev) =>
          prev.filter((key: IApiKeyDetails) => key.appId !== selectedAppId)
        );
      })
      .catch((e) => toast.error(e.message))
      .finally(() => {
        handleModalClose();
      });
  };

  const handleKeyRegenerate = async (appId: string) => {
    setSelectedAppId(appId);
    await regenerateKeyByID(appId)
      .then((res: IApiKeyDetails) => {
        toast.success("Key regenerated successfully");
        // update key value in the useState
        setKeys((prev) => {
          let index = prev.findIndex((u) => u.appId === appId);
          prev[index] = res;
          return [...prev];
        });
      })
      .catch((e) => toast.error(e.message))
      .finally(() => {
        handleModalClose();
      });
  };
  function handleModalClose() {
    setShowModal(false);
    setSelectedAppId("");
  }

  async function fetchAccessKeys() {
    await getAllKeys()
      .then((res: IApiKeyDetails[]) => {
        setKeys(res);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchAccessKeys();
  }, [resetTableTrigger]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="table-responsive">
      {keys.length > 0 ? (
        <table className="super-admin-table justify-content-center mx-auto caption-top">
          <thead>
            <tr>
              <th>
                <span>Key Name</span>
              </th>
              <th>
                <span className="header-cell">Email</span>
              </th>
              <th>
                <span className="header-cell">App ID</span>
              </th>
              <th>
                <span className="header-cell">API Key</span>
              </th>
              <th>
                <span className="header-cell">Last Updated</span>
              </th>
              <th>
                <span className="header-cell">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {keys.map((row, index) => (
              <tr key={index} data-testid="access-key-row">
                <td>{row.keyName}</td>
                <td>{row.email}</td>
                <td className="accesskey-table-cell-AppId">
                  <CopyableTextField fieldName="App Id" value={row.appId} />
                </td>
                <td className="accesskey-table-cell-ApiKey">
                  {!row.apiKey.includes("*") ? (
                    <CopyableTextField fieldName="API Key" value={row.apiKey} />
                  ) : (
                    row.apiKey
                  )}
                </td>
                <td className="text-center">
                  {formattedDate(row.dateUpdated)}
                </td>
                <td className="text-center accesskey-table-cell-Action">
                  <TooltipBtn
                    id="t-delete"
                    title="Delete Key"
                    action={() => {
                      setSelectedAppId(row.appId);
                      setShowModal(true);
                    }}
                    disable={showModal}
                  >
                    <AiOutlineDelete />
                  </TooltipBtn>
                  &nbsp;
                  <TooltipBtn
                    id="t-regenerate"
                    title="Regenerate Key"
                    action={() => handleKeyRegenerate(row.appId)}
                    disable={!!selectedAppId}
                  >
                    <GrRefresh />
                  </TooltipBtn>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Card className="mt-5 text-center" border="light">
          <Card.Body>No keys generated yet!</Card.Body>
        </Card>
      )}

      <ConfirmationModal
        show={showModal}
        handleClose={handleModalClose}
        handleConfirmation={handleDelete}
        desc="Are you sure you want delete this key?"
      />
    </div>
  );
}

export default ApiKeyTable;
