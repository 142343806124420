import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { InteractionStatus } from "@azure/msal-browser";
import { IContextUser, IAppContext } from "./Interfaces/IAppContext";

const placeholderCustomer: IContextUser = {
  oid: "",
  email: "",
  role: "",
};

export function getB2CUserDetails(idTokenClaims: any) {
  return {
    oid: idTokenClaims?.oid as string,
    email: idTokenClaims?.emails[0] as string,
    role: idTokenClaims?.jobTitle as string,
  };
}

export const AppContext = createContext<IAppContext>({
  user: placeholderCustomer,
});

export const useAppContext = () => {
  return useContext(AppContext);
};

export default function AppProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState(placeholderCustomer);
  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const getToken = async () => {
      const request = {
        scopes: [process.env.REACT_APP_B2C_CLIENT_ID as string],
        account: accounts[0],
      };

      try {
        const res = await instance.acquireTokenSilent(request);
        const token = res?.accessToken;
        const claims = res?.account?.idTokenClaims;
        sessionStorage.setItem("access_token", token);
        setUser(getB2CUserDetails(claims));
      } catch (e: any) {
        if (e?.errorMessage.includes("AADB2C90077")) {
          toast.error("Session expired. Logging out ...");
          // Logout user
          sessionStorage.clear();
          try {
            await instance.logoutRedirect();
          } catch (err: any) {
            toast.error("Logout failure ! Please try again ...");
          }
        }
      }
    };
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      getToken();
    }
  }, [accounts, instance, inProgress, isAuthenticated]);

  const context = useMemo(
    () => ({
      user,
    }),
    [user]
  );
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
