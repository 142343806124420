import React, { useEffect, useState } from "react";
import { postApiKey } from "../../../Services/SuperAdminService";
import { IApiKeyDetails } from "../../../Interfaces/IApiKeyDetails";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { validateEmail } from "../../../Utils/helper";
import { toast } from "react-toastify";

interface IGenerateApiKeyFormProps {
    setShowApiKey: (show: boolean) => void;
    setGeneratedApiKey: (generatedKey: IApiKeyDetails | null) => void;
    resetFormTrigger: boolean;
}

function GenerateApiKeyForm(props: IGenerateApiKeyFormProps) {
    const { setShowApiKey, setGeneratedApiKey, resetFormTrigger } = props;

    const [email, setEmail] = useState<string>("");
    const [keyName, setKeyName] = useState<string>("");

    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        resetForm();
    }, [resetFormTrigger]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);
        setIsValidEmail(validateEmail(enteredEmail));
    };

    const handleKeyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredKeyName = e.target.value;
        setKeyName(enteredKeyName);
    };

    const resetForm = () => {
        setEmail("");
        setKeyName("");
        setGeneratedApiKey(null);
        setShowApiKey(false);

        //remove autocomplete text
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input) => {
            input.value = "";
        });
    };

    const handleGenerateKey = async () => {
        if (!isLoading) {
            setIsLoading(true);
            await postApiKey({ email, keyName })
                .then((response) => {
                    if (response !== null) {
                        setGeneratedApiKey(response);
                    }
                    setShowApiKey(true);
                })
                .catch((e) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <Container
            fluid
            className="justify-content-center page-section"
            data-testid="GenerateApiKeyForm"
        >
            <h5 style={{ textAlign: "center" }}>Generate API Key</h5>
            <Form>
                <Row className="justify-content-center">
                    <Col sm={4}>
                        <Form.Group controlId="formColumn1">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={handleEmailChange}
                                data-testid="emailInput"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group controlId="formColumn2">
                            <Form.Control
                                type="text"
                                placeholder="Key Name"
                                value={keyName}
                                maxLength={50}
                                onChange={handleKeyNameChange}
                                data-testid="keyNameInput"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={2} className="d-flex justify-content-center">
                        <Button
                            type="submit"
                            variant="primary"
                            className="Button"
                            disabled={!isValidEmail || email.trim() === "" || keyName === "" || isLoading}
                            onClick={(e) => {
                                e.preventDefault();
                                handleGenerateKey();
                            }}
                            data-testid="generateButton"
                        >
                            <span>Generate</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default GenerateApiKeyForm;
