import { useState } from "react";
import { Card } from "react-bootstrap";
import { uploadCSVFile } from "../Services/IntroducerService";
import LoadingSpinner from "./LoadingSpinner";
import { useAppContext } from "../AppContext";
import { toast } from "react-toastify";

function UploadCard() {
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useAppContext();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files;
    if (file && file.length > 0) {
      // service
      setIsUploading(true);
      uploadCSVFile(file[0], user.oid)
        .then(() => {
          toast.success("File Upload Successfully");
          window.location.reload();
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => {
          setIsUploading(false);
          event.target.value = "";
        });
    }
  };

  return (
    <Card style={{ width: "15rem" }} className="text-center Card">
      {isUploading ? (
        <LoadingSpinner />
      ) : (
        <Card.Img
          variant="top"
          src="./images/upload_file.png"
          className="card-image mx-auto"
        />
      )}

      <Card.Body>
        <Card.Title>
          <h5 className="font-size-xs">Upload to Cloud</h5>
        </Card.Title>

        <form>
          <input
            type="file"
            id="fileControlBtn"
            hidden
            accept=".csv"
            disabled= {isUploading}
            onChange={handleFileChange}
          />
          <label
            className="Button"
            htmlFor="fileControlBtn"
            style={{
              padding: "7px",
              fontSize: "0.9rem",
            }}
          >
            <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              Upload File
            </span>
          </label>
          <br />
        </form>
      </Card.Body>
    </Card>
  );
}

export default UploadCard;
