import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

interface ConfirmationModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirmation: () => Promise<void>;
  desc: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  handleClose,
  handleConfirmation,
  desc,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  async function confirm() {
    setIsLoading(true);
    try {
      await handleConfirmation();
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{desc}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          data-testid="cancelBtn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="Button"
          data-testid="confirmBtn"
          disabled={isLoading}
          onClick={confirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
