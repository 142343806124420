import { useEffect, useState } from "react";
import { Button, Row, Stack } from "react-bootstrap";
import { AiOutlineFile } from "react-icons/ai";
import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
import {
  approveReferral,
  getAllFiles,
} from "../../../Services/ReviewerService";
import { ITableData } from "../../../Interfaces/ITableData";
import FileModal from "../../../Components/FileModal";
import ApproveFile from "./ApproveFile";
import RejectFile from "./RejectFile";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import ResetSortButton from "../../../Components/ResetSortButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReferralStatus } from "../../../Interfaces/IReferralStatus";
import { TABLE_COLUMNS } from "../../../Utils/constants";
import { formattedDate, getTake } from "../../../Utils/helper";

function ReviewerTable() {
  var take = getTake();

  const [hasFiles, setHasFiles] = useState(true);
  const [sortBy, setSortBy] = useState<string>("");
  const [files, setFiles] = useState<ITableData[]>([]);
  const [selectedFile, setSelectedFile] = useState<ITableData | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc" | null>(
    "desc"
  );
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isModalEditable, setIsModalEditable] = useState(false);
  const [limit, setLimit] = useState<number>(take);
  const [offset, setOffset] = useState<number>(0);
  const [isDescending, setIsDescending] = useState<boolean>(true);

  // Reusable code
  async function fetchFiles(
    limit: number,
    offset: number,
    sortBy: string,
    isDescending: boolean
  ): Promise<any[]> {
    setLimit(take);
    const fetchedFiles = await getAllFiles(limit, offset, sortBy, isDescending);
    return fetchedFiles;
  }

  async function getFiles() {
    try {
      const _files = await fetchFiles(limit, offset, "", isDescending);
      setFiles(_files);
      setHasFiles(_files.length >= take);
    } catch (error) {
      toast.error("Failed to fetch files. Please try again.");
    }
  }

  useEffect(() => {
    getFiles();
  }, []);

  async function handleSort(key: string) {
    const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);
    setHasFiles(true);
    setSortBy(key);
    setIsDescending(newSortDirection === "desc");
    setLimit(take);

    try {
      const _files = await fetchFiles(
        take,
        0,
        key,
        newSortDirection === "desc"
      );
      setFiles(_files);
    } catch (error) {
      toast.error("Error in sorting");
    } finally {
      setOffset(0);
    }
  }

  async function handleResetAll() {
    try {
      const _files = await fetchFiles(take, 0, "", true);
      setFiles(_files);
      setHasFiles(_files.length >= take);
    } finally {
      setSortBy("");
      setSortDirection("desc");
      setOffset(0);
      setLimit(take);
      setIsDescending(true);
    }
  }

  async function handleLoad() {
    const newOffset: number = offset + limit;
    setLimit(take);
    try {
      const _files = await fetchFiles(limit, newOffset, sortBy, isDescending);
      if (_files.length >= 1) {
        setFiles([...files, ..._files]);
        setOffset(newOffset);
      } else {
        setHasFiles(false);
      }
    } catch (error) {
      toast.error("Files couldn't be loaded. Please try again.");
    }
  }

  const handleFileClick = async (file: ITableData) => {
    setSelectedFile(file);
    if (file.status === ReferralStatus.PENDING) {
      setIsModalEditable(true);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
    setShowModal(false);
    setIsModalEditable(false);
  };

  const handleAcceptClick = (file: ITableData) => {
    setSelectedFile(file);
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async () => {
    try {
      await approveReferral(selectedFile?.fileId ?? "");
      toast.success("Referral approved!")
      getFiles();
    } catch (error) {
      toast.error("Failed to perform the action. Please try again.");
    }
    handleConfirmationClose();
  };

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
    setSelectedFile(null);
  };

  return (
    <>
      <div className="table-responsive">
        {files.length === 0 ? (
          <Card
            border="light"
            style={{ marginTop: "10%", alignItems: "center" }}
          >
            <Card.Body>No files uploaded yet.</Card.Body>
          </Card>
        ) : (
          <Row>
            <InfiniteScroll
              scrollThreshold={0.9}
              dataLength={files.length}
              next={handleLoad}
              hasMore={hasFiles}
              loader={<LoadingSpinner />}
              endMessage={
                <p className="end-message">You've reached the end.</p>
              }
            >
              <table
                className="table justify-content-center mx-auto caption-top"
                data-testid="reviewerTable"
              >
                <caption>
                  <ResetSortButton handleResetAll={handleResetAll} />
                </caption>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      onClick={() => handleSort(TABLE_COLUMNS.displayName)}
                    >
                      <span>
                        File Name
                        {sortBy === TABLE_COLUMNS.displayName &&
                          (sortDirection === "asc" ? " ↑" : " ↓")}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => handleSort(TABLE_COLUMNS.email)}
                    >
                      <span className="header-cell">
                        Email Id
                        {sortBy === TABLE_COLUMNS.email &&
                          (sortDirection === "asc" ? " ↑" : " ↓")}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => handleSort(TABLE_COLUMNS.dateUploaded)}
                    >
                      <span className="header-cell">
                        Uploaded Date
                        {sortBy === TABLE_COLUMNS.dateUploaded &&
                          (sortDirection === "asc" ? " ↑" : " ↓")}
                      </span>
                    </th>
                    <th
                      scope="col"
                      onClick={() => handleSort(TABLE_COLUMNS.status)}
                    >
                      <span className="header-cell">
                        Status
                        {sortBy === TABLE_COLUMNS.status &&
                          (sortDirection === "asc" ? " ↑" : " ↓")}
                      </span>
                    </th>
                    <th scope="col">
                      <span className="header-cell">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {files?.length > 0 &&
                    files.map((file) => (
                      <tr key={file.fileId}>
                        <td
                          style={{ whiteSpace: "pre-wrap" }}
                          className="filename-column"
                        >
                          <Button
                            className="Button-link"
                            onClick={() => handleFileClick(file)}
                          >
                            <span>
                              <AiOutlineFile />
                              {file.displayName}
                            </span>
                          </Button>
                        </td>
                        <td>
                          <span>{file.userEmail}</span>
                        </td>
                        <td>
                          <span className="header-cell">
                            {formattedDate(file.dateUploaded)}
                          </span>
                        </td>
                        <td>
                          <span className="header-cell">{file.status}</span>
                        </td>
                        <td>
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="header-cell"
                          >
                            <span data-testid="dataFlow_icon_approve">
                              <ApproveFile
                                isDisabled={file.status !== "Pending"}
                                onActionButtonClick={handleAcceptClick}
                                file={file}
                              />
                            </span>
                            <span data-testid="dataFlow_icon_reject">
                              <RejectFile
                                isDisabled={file.status !== "Pending"}
                                fileId={file.fileId}
                                onSuccess={getFiles}
                              />
                            </span>
                          </Stack>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </Row>
        )}
        <FileModal
          getFiles={getFiles}
          show={showModal}
          handleClose={handleCloseModal}
          file={selectedFile}
          isModalEditable={isModalEditable}
        />
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={handleConfirmationClose}
          handleConfirmation={handleConfirmation}
          desc="Are you sure you want to accept this file?"
        />
      )}
    </>
  );
}

export default ReviewerTable;
