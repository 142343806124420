export const USER_ROLES = {
  INTRODUCER: "Introducer",
  REVIEWER: "Reviewer",
  ADMIN: "Admin",
  SUPER_ADMIN: "SuperAdmin",
};

export const INTERNAL_ERROR_MSG = "Something went wrong";
export const FORBIDDEN_ERROR_MSG = "403 - Forbidden!";

export const TABLE_COLUMNS = {
  email: "user.Email",
  dateUploaded: "dateCreated",
  status: "Status",
  fileName: "fileName",
  displayName: "displayname",
  role: "role",
  adminEmail: "email",
  adminStatus: "accountActive",
};
