import { Col, Container, Modal, Row } from 'react-bootstrap';
import CopyableTextField from './CopyableTextField';


interface IApiKeyDisplayProps {
    show: boolean;
    keyName: string;
    email: string;
    apiKey: string;
    appId: string;
    onClose: () => void;
}

function ApiKeyDisplay(props: IApiKeyDisplayProps) {
    const { show, keyName, email, apiKey, appId, onClose } = props

    return (
        <Modal
            dialogClassName="api-display-modal"
            show={show}
            onHide={onClose}
            data-testid="ApiKeyDisplayModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5>Key Generated</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container
                    className="justify-content-center">
                    <p style={{ textAlign: 'center' }}>
                        Key '<strong>{keyName}</strong>' generated for <strong>{email}</strong></p>
                    <hr />
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <Col sm={3} className="d-flex justify-content-center align-items-center">
                            <div style={{ textAlign: 'center' }}>
                                <label className='generatekey-modal-label' htmlFor='App Id'>App Id:</label>
                            </div>
                        </Col>
                        <Col sm={9}
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: '100%' }}>
                            <CopyableTextField
                                fieldName="App Id"
                                value={appId}

                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={3}
                            className="d-flex justify-content-center align-items-center">
                            <label className='generatekey-modal-label' htmlFor='API Key'>API Key:</label>
                        </Col>
                        <Col sm={9}
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: '100%' }}
                            data-testid="copyableTextInputApiKey">
                            <CopyableTextField
                                fieldName="API Key"
                                value={apiKey}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
};

export default ApiKeyDisplay;