import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { USER_ROLES } from "../Utils/constants";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    switch (user.role) {
      case USER_ROLES.INTRODUCER:
        navigate("/introducer");
        break;
      case USER_ROLES.REVIEWER:
        navigate("/reviewer");
        break;
      case USER_ROLES.ADMIN:
        navigate("/admin");
        break;
      case USER_ROLES.SUPER_ADMIN:
        navigate("/superadmin");
        break;
    }
  }, [navigate, user]);

  return <div></div>;
}
