import { Col, Container, Row } from "react-bootstrap";
import UserTable from "../../Components/UserTable";
import DownloadCard from "../../Components/DownloadCard";
import UploadCard from "../../Components/UploadCard";
import ScrollToTop from "react-scroll-to-top";

function IntroducerPage() {
  return (
    <Container
      className="w-100"
      style={{
        height: "90vh",
      }}
    >
      <Row className="" style={{ height: "35%", marginTop: "3%" }}>
        <Col
          xs={6}
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <UploadCard />
        </Col>
        <Col
          xs={6}
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <DownloadCard />
        </Col>
      </Row>
      <Row
        style={{
          height: "70%",
          justifyContent: "center",
        }}
      >
        <UserTable />
      </Row>
      <ScrollToTop smooth className="scroll-top" color="white" />
    </Container>
  );
}

export default IntroducerPage;
