import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "./Utils/navigationClient";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AppProvider from "./AppContext";
import RoutesModule from "./Layout/Routes.module";
import "./App.css";
import { ToastContainer } from "react-toastify";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <div>
      <MsalProvider instance={pca}>
        <AppProvider>
          <RoutesModule />
        </AppProvider>
      </MsalProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
