import { Route, Routes } from "react-router-dom";
import IntroducerPage from "../Pages/Introducer/IntroducerPage";
import ReviewerPage from "../Pages/Reviewer/ReviewerPage";
import AdminPage from "../Pages/Admin/AdminPage";
import RouteGuard from "./RouteGuard";
import Layout from ".";
import { USER_ROLES } from "../Utils/constants";
import NotFound from "../Pages/NotFound";
import LandingPage from "../Pages/LandingPage";
import ApiKeyPage from "../Pages/SuperAdmin/ApiKeyPage";

const allRoutes = [
  {
    path: "/introducer",
    component: <IntroducerPage />,
    role: USER_ROLES.INTRODUCER,
  },
  {
    path: "/reviewer",
    component: <ReviewerPage />,
    role: USER_ROLES.REVIEWER,
  },
  {
    path: "/admin",
    component: <AdminPage />,
    role: USER_ROLES.ADMIN,
  },
  {
    path: "/superadmin",
    component: <AdminPage />,
    role: USER_ROLES.SUPER_ADMIN,
  },
  {
    path: "/ApiKeys",
    component: <ApiKeyPage />,
    role: USER_ROLES.SUPER_ADMIN,
  },
];

export default function RoutesModule() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route element={<Layout />}>
        <Route path="*" element={<NotFound />} />
        {allRoutes.map((item, index) => (
          <Route key={index} element={<RouteGuard allowedRole={item.role} />}>
            <Route key={index} path={item.path} element={item.component} />
          </Route>
        ))}
      </Route>
    </Routes>
  );
}
