export default function NoAccess() {
  return (
    <div className="d-flex align-items-center justify-content-center vh-90">
      <div className="text-center">
        <h1 className="display-1 fw-bold">403</h1>
        <p className="fs-3">
          <span className="text-danger">Forbidden</span>
        </p>
        <p className="lead">
          You don't have the permission to access this page
        </p>
      </div>
    </div>
  );
}
