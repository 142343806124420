import React, { useContext } from "react";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { USER_ROLES } from "../../../Utils/constants";
import { validateEmail } from "../../../Utils/helper";
import { AppContext } from "../../../AppContext";

interface InviteUserModalProps {
  show: boolean;
  onCancel: () => void;
  onSubmit: (data: { email: string; role: string }) => void;
}

function InviteUserModal({ show, onCancel, onSubmit }: InviteUserModalProps) {
  const [email, setEmail] = React.useState<string>("");
  const [role, setRole] = React.useState<string>("Select Role"); // Default value changed
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);
  const { user } = useContext(AppContext);

  const handleRoleChange = (e: any) => {
    setRole(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsValidEmail(validateEmail(enteredEmail));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isValidEmail && email.trim() !== "" && role !== "Select Role") {
      onSubmit({ email, role });
      handleCancel();
    }
  };

  const handleCancel = () => {
    setEmail("");
    setRole("Select Role");
    onCancel();
  };

  return (
    <Modal show={show} onHide={handleCancel} data-testid="inviteUserModal">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Invite User</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="justify-content-center">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="formColumn1">
                  <span>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pt-2">
                <span>
                  <Form.Select
                    aria-label="Default select example"
                    value={role}
                    onChange={handleRoleChange}
                    data-testid="inviteUserModalDropDown"
                  >
                    <option disabled>Select Role</option>
                    {user.role === USER_ROLES.SUPER_ADMIN && (
                      <option value={USER_ROLES.ADMIN}>Admin</option>
                    )}
                    <option value={USER_ROLES.REVIEWER}>Reviewer</option>
                    <option value={USER_ROLES.INTRODUCER}>Introducer</option>
                  </Form.Select>
                </span>
              </Col>
              <Col></Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          className="Button"
          onClick={handleSubmit}
          disabled={
            !isValidEmail || email.trim() === "" || role === "Select Role"
          }
        >
          <span>Send Invite</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InviteUserModal;
