import axiosInstance from "../Config/axiosConfig";
import {
  IApiKeyDetails,
  IPostApiKeyDetails,
} from "../Interfaces/IApiKeyDetails";

export async function uploadJSONFile(file: File): Promise<void> {
  const formData = new FormData();
  formData.append("file", file);

  return await axiosInstance.post("/api/templates", formData);
}

export async function getAllKeys(): Promise<IApiKeyDetails[]> {
  const result = await axiosInstance.get("/api/keys");
  return result.data;
}
export async function postApiKey(
  postApiKeyDetails: IPostApiKeyDetails
): Promise<IApiKeyDetails | null> {
  const response = await axiosInstance.post("/api/keys", {
    email: postApiKeyDetails.email,
    keyname: postApiKeyDetails.keyName,
  });
  if (!response?.data) return null;
  return response.data;
}

export async function deleteKeyByID(appId: string): Promise<void> {
  return await axiosInstance.delete(`/api/keys/${appId}`);
}

export async function regenerateKeyByID(
  appId: string
): Promise<IApiKeyDetails> {
  const result = await axiosInstance.patch(`/api/keys/${appId}`);
  return result.data;
}
