export const replaceCsvTableData = (
  rowIndex: number,
  cellIndex: number,
  value: string,
  modifiedData: string[]
) => {
  const newData = modifiedData;
  const rowData = newData[rowIndex + 1].split(",");
  rowData[cellIndex] = value;
  newData[rowIndex + 1] = rowData.join(",");
  return newData;
};

export const getTake = () => {
  if (window.innerHeight > 1279) {
    return 100;
  } else if (window.innerHeight > 870) {
    return 50;
  }
  return 20; // default value
};

export const checkEmptyCells = (data: string[]) => {
  const rows = data;
  const headers = rows[0].split(",");
  const dataRows = rows.slice(1);
  const result = [];
  for (let i = 0; i < headers.length; i++) {
    const columnHeader = headers[i];
    let isEmpty = false;
    for (let j = 0; j < dataRows.length; j++) {
      const rowData = dataRows[j].split(",");
      if (rowData.some((cell) => cell.trim() !== "")) {
        if (rowData.length > 0) {
          const cellValue = rowData[i];
          if (cellValue === "") {
            isEmpty = true;
            break;
          }
        }
      }
    }
    result.push({ column: columnHeader, isEmpty: isEmpty });
  }

  return result;
};

export const convertToCSV = (data: any[]) => {
  const headers = Object.keys(data[0]);
  const csvRows = [];
  csvRows.push(headers.join(","));
  data.forEach((row) => {
    const values = headers.map((header) => {
      const cell = row[header];
      return cell === null || cell === undefined ? "" : cell;
    });
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};

export const downloadFile = (data: BlobPart, fileName: string) => {
  const blob = new Blob([data], {
    type: "application/octet-stream",
  });
  const url = window.URL.createObjectURL(blob);
  const defaultTemplate = document.createElement("a");
  defaultTemplate.href = url;
  defaultTemplate.download = fileName;
  document.body.appendChild(defaultTemplate);
  defaultTemplate.click();
  document.body.removeChild(defaultTemplate);
};

//Format date string to DD-MM-YYYY
export const formattedDate = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formatted = new Date(date).toLocaleDateString("en-US", options);
  const [mm, dd, yyyy] = formatted.split("/");
  return `${dd}-${mm}-${yyyy}`;
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
