import { useState } from "react";
import { getDefaultCSVTemplate } from "../Services/IntroducerService";
import { toast } from "react-toastify";

export function useTemplate() {
    const [isDownloading, setIsDownloading] = useState(false);

    async function downloadTemplate() {
        if (isDownloading) return;

        setIsDownloading(true);

        getDefaultCSVTemplate()
            .then((template) => {
                if (template) {
                    toast.success("Template Downloaded Successfully");
                }
            })
            .catch((e) => {
                toast.error(e.message);
            })
            .finally(() => {
                setIsDownloading(false);
            });
    }

    return {
        downloadTemplate,
        isDownloading,
    };
}
