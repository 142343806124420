import { Container, Row, Button, Navbar, Spinner } from "react-bootstrap";
import { IoMdDownload } from "react-icons/io";
import AdminTable from "./Components/AdminTable";
import ScrollToTop from "react-scroll-to-top";
import { useContext, useState } from "react";
import {
  deleteUserById,
  getUserLogs,
  patchUser,
} from "../../Services/AdminService";
import { toast } from "react-toastify";
import { IAdminLoadingAction } from "../../Interfaces/IAdminLoadingAction";
import { AppContext } from "../../AppContext";
import { IUserDetails, UserStatus } from "../../Interfaces/IUserDetails";
import ConfirmationModal from "../../Components/ConfirmationModal";
import { USER_ROLES } from "../../Utils/constants";

function AdminPage() {
  const [selectedUser, setSelectedUser] = useState<IUserDetails | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [userList, setUserList] = useState<IUserDetails[]>([]);
  const { user } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<IAdminLoadingAction>({
    REVOKE: false,
    DELETE: false,
    LOGS: false,
    USERS: false,
  });

  async function downloadUserLog() {
    setIsLoading((prev) => ({ ...prev, LOGS: true }));
    if (selectedUser) {
      await getUserLogs(selectedUser.b2CRef)
        .then((response: boolean) => {
          if (response) toast.success("User Logs Downloaded Successfully");
          else toast.info("No Records Found for the user");
        })
        .catch((e) => {
          toast.error(e.message);
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, LOGS: false }));
        });
    }
  }

  async function handleDelete() {
    setIsLoading((prev) => ({ ...prev, DELETE: true }));
    if (selectedUser) {
      await deleteUserById(selectedUser.b2CRef)
        .then((res: IUserDetails) => {
          if (res.deleted) {
            toast.success("User Deleted Successfully.");
            setUserList((prevUserList) =>
              prevUserList.filter((user) => user.b2CRef !== res.b2CRef)
            );
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, DELETE: false }));
          setShowConfirmModal(false);
          setSelectedUser(null);
        });
    }
  }

  async function handleRevoke() {
    setIsLoading((prev) => ({ ...prev, REVOKE: true }));
    if (selectedUser) {
      const status =
        selectedUser.status === UserStatus.Revoked
          ? UserStatus.Invited
          : UserStatus.Revoked;
      await patchUser(selectedUser.b2CRef, { status })
        .then((res: IUserDetails) => {
          toast.success("User Updated Successfully.");
          setSelectedUser(res);
          setUserList((prevUserList) => {
            let index = prevUserList.findIndex(
              (u) => u.b2CRef === selectedUser.b2CRef
            );
            prevUserList[index] = res;
            return [...prevUserList];
          });
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, REVOKE: false }));
          setShowConfirmModal(false);
          setSelectedUser(null);
        });
    }
  }

  let disableLogsButton =
    !selectedUser ||
    isLoading.LOGS ||
    (user.role == USER_ROLES.ADMIN &&
      (selectedUser?.role == USER_ROLES.SUPER_ADMIN ||
        selectedUser?.role == USER_ROLES.ADMIN));

  return (
    <Container className="PageContainer">
      <Navbar
        data-testid="actionsNavbar"
        style={{ backgroundColor: "white" }}
        className="w-100 pt-5 pb-3 d-flex justify-content-center"
        sticky="top"
      >
        <Button
          className="me-2 mb-2 admin-button"
          disabled={
            !selectedUser ||
            isLoading.REVOKE ||
            selectedUser?.role === USER_ROLES.SUPER_ADMIN
          }
          data-testid="btnRevoke"
          onClick={handleRevoke}
        >
          <span>
            {selectedUser && selectedUser.status === UserStatus.Revoked
              ? "Grant Access"
              : "Revoke"}
          </span>
        </Button>
        {user.role === USER_ROLES.SUPER_ADMIN && (
          <Button
            data-testid="btnDelete"
            className="me-2 mb-2 admin-button delete"
            onClick={() => setShowConfirmModal(true)}
            disabled={
              !selectedUser ||
              isLoading.DELETE ||
              selectedUser?.role === USER_ROLES.SUPER_ADMIN
            }
          >
            <span>Delete</span>
          </Button>
        )}
        <Button
          data-testid="btnUserLogs"
          onClick={downloadUserLog}
          className="me-2 mb-2 admin-button"
          disabled={disableLogsButton}
        >
          {isLoading.LOGS ? (
            <div style={{ minWidth: "4rem", padding: "1px" }}>
              <Spinner animation="border" size="sm" />
            </div>
          ) : (
            <span>
              User Logs <IoMdDownload />
            </span>
          )}
        </Button>
      </Navbar>
      <Container className="mt-3">
        <Row>
          <AdminTable
            data-testid="adminTable"
            {...{ userList, setSelectedUser, selectedUser, setUserList }}
          />
        </Row>
      </Container>
      <ScrollToTop smooth className="scroll-top" color="white" />
      <ConfirmationModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleConfirmation={handleDelete}
        desc="Are you sure you want delete this user?"
      />
    </Container>
  );
}

export default AdminPage;
