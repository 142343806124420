import React from "react";
import { Modal, Button } from "react-bootstrap";

interface LogoutModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function LogoutModal({ show, onCancel, onConfirm }: LogoutModalProps) {
  return (
    <Modal show={show} onHide={onCancel} data-testid="logoutConfirmationModal">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Confirm Logout</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to logout?</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          <span>Cancel</span>
        </Button>
        <Button
          className="Button ml-2"
          onClick={onConfirm}
          data-testid="logoutConfirmationButton"
        >
          <span>Logout</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogoutModal;
