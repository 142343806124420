import { Button } from "react-bootstrap";
import { GrPowerReset } from "react-icons/gr";

function ResetSortButton({ handleResetAll }: { handleResetAll: () => void }) {
  return (
    <div className="justify-content-start">
      <span>
        <Button
          className="reset"
          style={{ marginLeft: "5px" }}
          onClick={handleResetAll}
          data-testid="resetSort"
        >
          sort <GrPowerReset />
        </Button>
      </span>
    </div>
  );
}

export default ResetSortButton;
