import { TooltipBtn } from "../../../Components/TooltipBtn";
import {
  Form,
  InputGroup
} from "react-bootstrap";
import { toast } from "react-toastify";
import { FaCopy } from "react-icons/fa";

function CopyableTextField(props: { fieldName: string, value: string }) {
  const { fieldName, value } = props;

  const handleCopy = async (fieldName: string, value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      toast.success(`${fieldName} copied to clipboard.`);

    } catch (error) {
      toast.error("Error copying to clipboard");
    }

  };

  return (
    <InputGroup className="d-flex justify-content-center align-items-center">
      <Form.Control
        readOnly
        value={value}
        disabled
        id={fieldName}
        test-dataid={"copyableTextInput" + fieldName}
        className="copyable-textfield"
      />
      <TooltipBtn title={`Copy ${fieldName}`}
        id={`copy-button-${fieldName}`}
        action={() => handleCopy(fieldName, value)}
        variant={"outline-secondary"}
        test-dataid={"fieldNamecopyButton"}>
        <FaCopy />
      </TooltipBtn>
    </InputGroup>
  );
}

export default CopyableTextField;