import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { uploadJSONFile } from "../Services/SuperAdminService";
import { toast } from "react-toastify";

function UploadTemplateButton() {
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files;
        if (file && file.length > 0) {
            setIsUploading(true);
            uploadJSONFile(file[0])
                .then(() => {
                    toast.success("File Upload Successfully");
                })
                .catch((e) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        }
    };

    return (
        <form>
            <input
                type="file"
                id="fileControlBtn"
                hidden
                accept=".json"
                disabled={isUploading}
                onChange={handleFileChange}
                data-testid="NavLinkUpload"
            />
            <label
                htmlFor="fileControlBtn"
                style={{
                    background: "none",
                    border: "none",
                    marginTop: "5px ",
                }}
                className="navbar-item"
            >
                <Nav.Item className="navbar-items">Upload Template</Nav.Item>
            </label>
        </form>
    );
}

export default UploadTemplateButton;
